import { getDoc, doc, updateDoc, setDoc, collection, query, getDocs, addDoc, memoryEagerGarbageCollector, where, orderBy } from "firebase/firestore"
import db from "./firebase-config"

//import { arrCategoryPap } from "../data/maestro_categorias_pap";
import { store } from "../store/store";

export const generaNotificacion = async (objNotificacion) => {

    try {
        //console.log("se recibe ");
        //console.log(objNotificacion);
        const notifRef = collection(db, 'notificacion_stock');
        const resp = await addDoc(notifRef, objNotificacion)
        //console.log(resp.id)
        //console.log("INGRESADO");
        return { ingresado: true, id: resp.id }
    } catch (error) {
        console.error(error);
        return { actualizado: false, error: error };
    }
}


export const generaPedido = async (objPedido) => {

    try {
        //console.log("se recibe ");
        //console.log(objPedido);
        const pedidosRef = collection(db, 'pedidosVO2');
        const resp = await addDoc(pedidosRef, objPedido)
        //console.log(resp.id)
        //console.log("INGRESADO");
        return { ingresado: true, id: resp.id }
    } catch (error) {
        console.error(error);
        return { actualizado: false, error: error };
    }
}

export const getDatosPedido = async (uidPedido) => {
    let respuesta = {
        obtenido: false
    }
    //console.log(uidPedido)
    try {
        const docRef = doc(db, 'pedidosVO2', uidPedido)
        const docSnap = await getDoc(docRef)
        respuesta.data = docSnap.data()
        respuesta.obtenido = true

    } catch (error) {
        //console.log(error)
        respuesta.error = error
    }

    return respuesta;

}


// export const getDatosPedidoInvitado = async (uidPedido) => {
//     let respuesta = {
//         obtenido: false
//     }
//     //console.log(uidPedido)
//     try {
//         const docRef = doc(db, 'pedidos_invitado', uidPedido)
//         const docSnap = await getDoc(docRef)
//         respuesta.data = docSnap.data()
//         respuesta.obtenido = true

//     } catch (error) {
//         //console.log(error)
//         respuesta.error = error
//     }

//     return respuesta;

// }

// export const getDatosPedidoRegistrado = async (uid, uidPedido) => {
//     //console.log(uid)
//     let respuesta = {
//         obtenido: false
//     }
//     //console.log(uidPedido)
//     try {
//         const docRef = doc(db, 'humano', uid, 'pedidosVO2', uidPedido)
//         const docSnap = await getDoc(docRef)
//         respuesta.data = docSnap.data()
//         respuesta.obtenido = true

//     } catch (error) {
//         //console.log(error)
//         respuesta.error = error
//     }

//     return respuesta;
// }

export const getUsuario = async (uid) => {

//console.log("usuario desde firebase");
//console.log(uid);
    const docRef = doc(db, "humano", uid)
    const docSnap = await getDoc(docRef)
    return docSnap.data();
}

export const updateUsuario = async (uid, objData) => {

    //recibo un objeto, por lo cual actualiza sólo la propiedad del objeto
    try {
        const docRef = doc(db, "humano", uid);
        await updateDoc(docRef, objData)
        //console.log("Campo actualizado con éxito");
        return { actualizado: true };
    } catch (error) {
        console.error("Error al actualizar el campo", error);
        return { actualizado: false, error: error };
    }
}

// export const generaPedidoRegistrado = async (uid, objPedido) => {

//     try {
//         //console.log("se recibe ");
//         //console.log(objPedido);
//         const humanoRef = doc(db, 'humano', uid);
//         const pedidoRef = collection(humanoRef, 'pedidosVO2')
//         const resp = await addDoc(pedidoRef, objPedido)
//         //console.log(resp.id)
//         //console.log("INGRESADO");
//         return { ingresado: true, id: resp.id }
//     } catch (error) {
//         console.error(error);
//         return { actualizado: false, error: error };
//     }
// }


// export const generaPedidoInvitado = async (objPedido) => {

//     try {
//         //console.log("se recibe ");
//         //console.log(objPedido);
//         const pedidoInvitadoRef = collection(db, 'pedidos_invitado');
//         const resp = await addDoc(pedidoInvitadoRef, objPedido)
//         //console.log(resp.id)
//         //console.log("INGRESADO");
//         return { ingresado: true, id: resp.id }
//     } catch (error) {
//         console.error(error);
//         return { actualizado: false, error: error };
//     }
// }
export const registerUsuario = async (objData) => {
    try {
        //console.log("se recibe " + objData.uid);
        //console.log(objData);
        await setDoc(doc(db, 'humano', objData.uid), objData)
        //console.log("INGRESADO");
        return { ingresado: true, uid: objData.uid}
    } catch (error) {
        return { ingresado: false, error: error }
    }
}

export const validaComuna = async (objDireccion) => {
    let resultado = {
        comunaValida: false,
    }
    try {
        //console.log("se recibe " + objDireccion.comuna + " " + objDireccion.region);
        //trae los datos de firebase desde coleccion sistema, documento appliga, subcoleccion comunas_disponibles
        const query = collection(db, "publico", "comunas", "comunas_disponibles")
        const querySnapshot = await getDocs(query);

        //recorre los datos de firebase y los compara con los datos recibidos

        querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());

            if (doc.data().region === objDireccion.region) {
                //console.log("region valida");
                //valida si la comuna se encuentra en el arreglo comunas del documento
                if (doc.data().comunas.includes(objDireccion.comuna)) {
                    //console.log("comuna valida");
                    resultado.comunaValida = true;
                    //console.log(resultado)

                }
            }

        });

    } catch (error) {
        //console.log("error al recibir datos de firebase");
        //console.log(error);
        resultado.error = error;

    }
    return resultado;
}

export const getPedidos = async (uid) => {

    let pedidos = [];
    try {
        const q =query(collection(db, "pedidosVO2")
                        ,where('uid','==',uid)
                        ,orderBy('fecha_pedido', 'desc'))
        
        const querySnapshot = await getDocs(q);
        //console.log("pedidos recibidos")
        querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            pedidos.push(doc.data());
        });

    }
    catch (error) {
        //console.log("error al recibir datos de firebase");
        //console.log(error);
    }
    return pedidos;
}

export const getCotizaciones = async (uid) => {

    let cotizaciones = [];
    try {
        const query = collection(db, "humano", uid, "cotizaciones")
        const querySnapshot = await getDocs(query);

        querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            cotizaciones.push(doc.data());
        });

    }
    catch (error) {
        //console.log("error al recibir datos de firebase");
        //console.log(error);
    }
    return cotizaciones;
}

//actualiza el arreglo de direcciones en firebase
export const updateDireccionesFirebase = async (uid, objDireccion) => {
    let resultado = { actualizado: false }
    try {
        const docRef = doc(db, "humano", uid);
        await updateDoc(docRef, {
            direcciones: objDireccion
        })
        //console.log("Campo actualizado con éxito");
        resultado.actualizado = true;

    } catch (error) {
        console.error("Error al actualizar el campo", error);
        resultado.actualizado = false;
        resultado.error = error;
    }
    return resultado;
}


//actualiza el arreglo de pacientes en firebase
export const updatePacientesFirebase = async (uid, arrPacientes) => {
    let resultado = { actualizado: false }
    try {
        const docRef = doc(db, "humano", uid);
        await updateDoc(docRef, {
            pacientes: arrPacientes
        })
        //console.log("Campo actualizado con éxito");
        resultado.actualizado = true;

    } catch (error) {
        console.error("Error al actualizar el campo", error);
        resultado.actualizado = false;
        resultado.error = error;
    }
    return resultado;
}


//actualiza el objeto pedido_en_curso del usuario en firebase
export const updatePedidoEnCursoFirebase = async (uid, arrPedidoEnCurso) => {
    let resultado = { actualizado: false }


    //console.log("uid desde store");
    //console.log(uid);
    let objPedidoEnCurso = {
        fecha_actualizacion: new Date(),
        carro: arrPedidoEnCurso
    }
    //console.log("objPedidoEnCurso");
    //console.log(objPedidoEnCurso);
    try {
        const docRef = doc(db, "humano", uid);
        await updateDoc(docRef, {
            pedido_en_curso: objPedidoEnCurso
        })
        //console.log("Campo actualizado con éxito");
        resultado.actualizado = true;

    } catch (error) {
        console.error("Error al actualizar el campo", error);
        resultado.actualizado = false;
        resultado.error = error;
    }
    return resultado;
}

//actualiza el arreglo de documentos del usuario en firebase
export const updateDocumentosFirebase = async (uid, arrDocumentos) => {
    let resultado = { actualizado: false }
    try {
        const docRef = doc(db, "humano", uid);
        await updateDoc(docRef, {
            documentos: arrDocumentos
        })
        //console.log("Campo actualizado con éxito");
        resultado.actualizado = true;

    } catch (error) {
        console.error("Error al actualizar el campo", error);
        resultado.actualizado = false;
        resultado.error = error;
    }
    return resultado;
}

export const getCartasDesabastecimiento = async () => {

    let cartas = [];
    try {
        
        const q= query(collection(db, "publico", "regulatorio", "cartas_desabastecimiento")
        , where("activo", "==", true))
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            cartas.push(doc.data());
        });

    }
    catch (error) {
        //console.log("error al recibir datos de firebase");
        //console.log(error);
    }
    return cartas;
}


export const getMedicamentos = async  ()=> {
    //console.log("get store")
    //console.log(store.getState().modalidad.modalidadSeleccionada.presku)

      //obtengo el arreglo de fotos
  const docRefFotos = doc(db, "publico", "fotos_medicamentos")
  const docSnapFotos = await getDoc(docRefFotos)
  let arrFotosFirebase = docSnapFotos.data().images_med
   //console.log("arrFotosFirebase")
  // console.log(arrFotosFirebase[0])


  // const docRef = doc(db, "publico", "presku1")   obteniendo stock desde bodega central, se modifica temporalmente
  const docRef = doc(db, "publico", store.getState().modalidad.modalidadSeleccionada.presku)
 // console.log("obtengo desde "+store.getState().modalidad.modalidadSeleccionada.presku)
  const docSnap = await getDoc(docRef)
  let arrPrevio = docSnap.data().sku
  //console.log(arrPrevio)

  for (const element of arrPrevio) {
    //console.log(element.kinf)
    try {
      let obj = arrFotosFirebase.find(e => e.kinf === element.kinf && e.kinf2.substr(-4)===element.kinf2.substr(-4))
      element.image = obj.image
    } catch (error) {
      //sin imagen
      element.image = ['/assets/img/sin_foto.png']
      //imagen actual
      //element.image=[element.img_url_0]
    }
  }

   //console.log("carga completa")

  arrPrevio.sort((a, b) => a.nombre_medicamento.toLowerCase() > b.nombre_medicamento.toLowerCase() ? 1 : -1,)

  //combino arrPrevio con arrCategoryPap cuando coincida el kinf y presentacion
//   arrPrevio.forEach((element, index) => {
//     let obj = arrCategoryPap.find(e => e.kinf === element.kinf && e.pres === element.pres)
//     if (obj) {
//       arrPrevio[index].categorias = obj.categorias
//       arrPrevio[index].pap_maestro = obj.programa
//       arrPrevio[index].pap = obj.pap
//     } else {
//       arrPrevio[index].categorias = []
//       arrPrevio[index].pap_maestro = false
//       arrPrevio[index].pap = null
//     }
//   })
  //console.log(arrPrevio)
//   store.dispatch(setProducts(arrPrevio));
//   store.dispatch(setProductsFull(arrPrevio))

//const medicamentosSinFoto = arrPrevio.filter(product => product.image[0] === "/assets/img/sin_foto.png")
//console.log("medicamentos sin foto")
  ////console.log(sinfoto)
//   let arreglo = []
//   medicamentosSinFoto.forEach(item => {
//       let obj =
//       {
//           kinf: item.kinf,
//           pres: item.pres,
//           nombre_medicamento: item.nombre_medicamento
//       }
//       arreglo.push(obj)
//   })

//agregar codigo aqui
// arrPrevio = arrPrevio.filter(item => {
// //console.log("item kinf2")
// //console.log(item)
//     // Buscar si el elemento actual de arrPrevio está presente en sinfoto
//     return !medicamentosSinFoto.some(medicamentoSinFoto => {
//       return medicamentoSinFoto.kinf2 === item.kinf2;
//     });
//   });
//console.log("paso por aca")
// arrPrevio = arrPrevio.filter(item=> item.image[0] !== "/assets/img/sin_foto.png")
// console.log(arrPrevio)



arrPrevio = arrPrevio.filter(item=> !item.nombre_medicamento.includes("(IMP)"))
//console.log(arrPrevio)



// console.log("med sin fotos")

// console.log(arrPrevio.filter(product => (product.image[0] === '/assets/img/sin_foto.png')  || product.image.length===0))

let arrFinal = arrPrevio.filter(product => (product.image[0] !== '/assets/img/sin_foto.png' || product.image.length !==0))
//console.log(arrFinal)
// arrPrevio.forEach(item => {
// //console.log(item.kinf2)
// })
return arrFinal;

}



export const getFichaTecnica = async (kinf) => {

    let cartas = [];
    try {
        const q = query(collection(db, "publico", "regulatorio", "fichas_tecnicas"), where("activo", "==", true), where("kinf", "==", kinf))
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            let obj = {
                id: doc.id,
                ...doc.data()
            }
            cartas.push(obj);
        });

    }
    catch (error) {
        //console.log("error al recibir datos de firebase");
        //console.log(error);
    }
    return cartas;
}