import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SEO = ({ title, titleTemplate, description }) => {
    function capitalizeEachWord(text) {
        text = text.toLowerCase();
        if (!text) return "";
        
        return text.replace(/([^\s()]+)(\s|\(|\)|$)/g, (match, word, separator) => {
            // Mantiene la palabra intacta excepto por la primera letra que la vuelve mayúscula
            return word.charAt(0).toUpperCase() + word.slice(1) + separator;
        });
    }
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {capitalizeEachWord(description)} - {capitalizeEachWord(titleTemplate)}
                </title>
                <meta name="description" content={capitalizeEachWord(description) + " - Farmacia Online - Liga Chilena contra la Epilepsia"} />
                {/* <meta name="description" content={description +" - " + title +" | "+ titleTemplate} />
             */}
                <meta property="og:title" content={capitalizeEachWord(description) + " - Farmacia Online - Liga Chilena contra la Epilepsia"}></meta>
                <meta property="og:description" content={capitalizeEachWord(titleTemplate)}></meta>


            </Helmet>
        </HelmetProvider>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    titleTemplate: PropTypes.string,
    description: PropTypes.string,
}

SEO.defaultProps = {
    title: "Farmacia Online",
    titleTemplate: "Liga Chilena contra la Epilepsia",
    description: "Venta Online 2.0 - Liga Chilena contra la Epilepsia.",
};

export default SEO;